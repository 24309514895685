import { z, ZodTypeAny } from "zod";

import { getEnvVariable } from "~shared/get-env-variable.ts";

export const DEVICES_TIMEZONE = "Europe/Berlin";

export interface Alert {
	severity: "error" | "warning" | "info" | "success";
	message: string;
}

export enum AssignedDevicesType {
	"NO_DEVICES" = "NO_DEVICES",
	"MANY_DEVICES" = "MANY_DEVICES",
	"ONE_AIR_CUBE" = "ONE_AIR_CUBE",
	"ONE_HEAT_CUBE" = "ONE_HEAT_CUBE",
}

export const autocompleteArray = (arrayValidators: z.ZodArray<ZodTypeAny>) => {
	return z
		.string()
		.transform((val) => {
			return val === "" ? [] : val.split(",");
		})
		.pipe(arrayValidators);
};

export const routesWithoutAuth = new Set([
	"/login",
	"/login/activate-account",
	"/login/forgot-password",
	"/login/reset-password",
	"/fair-demo",
	"/devices-links",
]);

export const ZIndex = {
	base: "z-10",
	menu: "z-20",
};

export const MAX_DEVICES_CREATED_TOGETHER = 50;
export const MAX_CHANGELOG_LENGTH = 200;

export const ACTIVATE_ACCOUNT_ERROR_MESSAGES: Record<string, string> = {
	NotAuthorizedException: "wrongActivationCode",
	LimitExceededException: "toManyTriesTryAgainLater",
	ExpiredCodeException: "resetLinkExpired",
	TooManyFailedAttemptsException: "toManyTriesTryAgainLater",
	TooManyRequestsException: "toManyTriesTryAgainLater",
	InternalFailure: "noInternetConnection",
	ServiceUnavailable: "serviceUnavailableTryAgainLater",
};

export const CHANGE_PASSWORD_MESSAGES: Record<string, string> = {
	...ACTIVATE_ACCOUNT_ERROR_MESSAGES,
	NotAuthorizedException: "wrongCurrentPassword",
};

export const LOGIN_ERROR_MESSAGES: Record<string, string> = {
	...ACTIVATE_ACCOUNT_ERROR_MESSAGES,
	NotAuthorizedException: "wrongEmailOrPassword",
};

export const SENTRY_CONFIG = {
	dsn: getEnvVariable("SENTRY_DSN"),
	environment: getEnvVariable("DEPLOY_ENV"),
	release: getEnvVariable("LATEST_COMMIT_SHA"),
	ignoreErrors: [
		"You cannot `useLoaderData` in an errorElement (routeId: root)",
	],
	denyUrls: [
		/\/build\//,
		/\/favicons\//,
		/\/images\//,
		/\/fonts\//,
		/\/favicon.png/,
		/\/site\.webmanifest/,
	],
	enabled:
		getEnvVariable("DEPLOY_ENV") != "local" &&
		getEnvVariable("NODE_ENV") != "test",
};
export const DEMO_HEATING_INSTALLER_EMAIL = "heizungsbauer@demo.de";
export const DEMO_CUSTOMER_EMAIL = "kunde@demo.de";

export const TEST_CUSTOMER_EMAIL = "customer@e2e-tests.com";
export const TEST_ADMIN_EMAIL = "admin@e2e-tests.com";

// make tests faster
// exported constants cannot be mocked
// and fake timers does not work with database connections
export const TESTS_TIME_ACCELERATOR =
	getEnvVariable("NODE_ENV") === "test" ? 1 / 30 : 1;
